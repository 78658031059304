<script setup lang="ts">
const route = useRoute()
useHead({
  titleTemplate: () => {
    return 'Workforce Development Institute'
  }
})

</script>
<template>
    <UContainer>
        <div class="flex flex-col md:flex-row justify-between space-y-4 items-center pt-5 mb-5 md:mb-10 px-2 md:px-10 xl:px-20">
            <img src="/wdi-logo-rgb.png" alt="WDI Logo" class="h-12 md:h-20 flex-none">
            <span class="block font-bold text-xl md:text-2xl text-gray-700">{{ $t(route.meta.title) }}</span>
        </div>
        <slot />
    </UContainer>
</template>